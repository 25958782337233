/**
 *  Background Image
 *
 */

import globals from '../settings/globals';

const breakpointAssociations = ['xs', 'sm', 'md', 'lg', 'xl'];

class ResponsiveBackground {
  constructor() {
    this.attribute = 'data-bg-img';
    this.elements = document.querySelectorAll('[' + this.attribute + ']');
    this.devicePixelRatio = this.getPixelRatio();
    this.setBackgroundImagesArray();
    this.setBackgroundImages();

    document.addEventListener('BreakpointChange', function () {
      this.setBackgroundImages();
    }.bind(this));
  }

  getPixelRatio() {
    let ratio = window.devicePixelRatio;

    if (ratio > 1 && ratio < 2) {
      ratio = 1;
    } else if (ratio > 2 && ratio < 3) {
      ratio = 2;
    }

    return ratio
  }

  setBackgroundImagesArray() {
    for (var i = 0; i < this.elements.length; i++) {
      let element = this.elements[i];
      let backgroundImages = this.getEmptyBackgroundImagesArray();

      for (var key in backgroundImages) {
        let attribute = element.getAttribute('data-bg-img-' + key);

        backgroundImages[key] = (attribute !== null) ? attribute.split(' ') : null;
      }

      element.backgroundImages = backgroundImages;

      this.setBackgroundImage(element);
    }

    return this;
  }

  setBackgroundImages() {
    for (var i = 0; i < this.elements.length; i++) {
      let element = this.elements[i];

      this.setBackgroundImage(element);
    }
  }

  setBackgroundImage(element) {
    let activeBreakpointImage = element.backgroundImages[globals.activeBreakpointRange];

    if (activeBreakpointImage !== null) {
      this.setBackgroundImageAttribute(element, activeBreakpointImage[this.devicePixelRatio - 1]);
    } else {
      let breakpointIndex = breakpointAssociations.indexOf(globals.activeBreakpointRange);

      for (var i = breakpointIndex - 1; i >=0; i--) {
        let underActiveBreakpointImage = element.backgroundImages[breakpointAssociations[i]];

        if (underActiveBreakpointImage !== null) {
          this.setBackgroundImageAttribute(element, underActiveBreakpointImage[this.devicePixelRatio - 1]);

          break;
        }
      }
    }
  }

  getEmptyBackgroundImagesArray() {
    return {
      xs: [],
      sm: [],
      md: [],
      lg: [],
      xl: []
    };
  }

  setBackgroundImageAttribute(element, url) {
    element.style.backgroundImage = 'url(\'' + url + '\')';
  }
}

export default ResponsiveBackground;
