import $ from 'jquery';

let $selectElements;

window.jQuery(document).bind('gform_post_render', function(){
  setSelectElements();
});

function setSelectElements() {
  $selectElements = $('select');

  $selectElements.on('change', function () {
    controlSelect($(this));
  });
}

function controlSelect($select) {
  if ($select.val() !== '') {
    $select.addClass('selected');
  } else {
    $select.removeClass('selected');
  }
}

setSelectElements();
