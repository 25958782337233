let $forms = jQuery('.form--revealing form');

$forms.each(function () {
  let $form = jQuery(this);
  let $submit = $form.find('input[type="submit"]');
  let first = true;

  $submit.val('Continue');
  $form.find('.js-hidden-field').hide();

  $submit.click(function (e) {
    e.preventDefault();

    if (first) {
      $submit.val('Submit Enquiry');
      $form.find('.js-hidden-field').slideDown(400);
      $form.closest('.form--revealing').addClass('form--revealed');
      first = false;
    }
    else  {
      $form.submit();
    }
  });
});
