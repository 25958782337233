/**
 * Modal
 *
 * @Author Konstantinos Kataras
 *
 */

import $ from 'jquery';
import ClickOut from './click-out.js';
import bodyScrolling from './stop-scroll';

function Modal ($modal, options) {
  let body = $modal.find('[data-modal="body"]');
  let loader = $modal.find('[data-modal="loader"]');
  let isOpened = false;
  let loaded = false;

  if (typeof options !== 'undefined' && options.clickOut) {
    new ClickOut({
      element: $modal.find('[data-modal="content"]'),
      selector: '[data-modal="content"]',
      eventName: 'modalClickOut',
      exceptionSelector: '[data-modal="click-out-exception"]',
      exceptionElements: $modal.find('[data-modal="click-out-exception"]')
    });

    $(document).on('modalClickOut', function() {
      if (isOpened) {
        this.close();
      }
    }.bind(this));
  }

  $(document).keyup(function(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }.bind(this));

  $modal.find('[data-modal="close"]').on('click', function (e) {
    e.preventDefault();
    this.close();
  }.bind(this));

  this.open = function () {
    $modal.addClass('modal--open');

    setTimeout(function () {
      isOpened = true;
      this.onOpen();
    }.bind(this), 300);

    $('body').css({
      'overflow': 'hidden',
      'position': 'relative',
      'height': '100%'
    });
  };

  this.close = function () {
    $modal.removeClass('modal--open');
    this.onClose();
    isOpened = false;
    $modal.removeClass('modal--loaded');
    loaded = false;

    $('body').css({
      'overflow': '',
      'position': '',
      'height': ''
    });
  };

  function expand () {
    $modal.addClass('modal--loaded')
  }

  this.setLoaded = function(state) {
    loaded = state;
    (loaded) ? hideLoader() : showLoader();
    (loaded) ? expand() : showLoader();

    return this;
  };

  function hideLoader() {
    loader.hide();
  }

  function showLoader() {
    loader.show();
  }

  this.getBody = function () {
    return body;
  };

  this.onOpen = function () {};

  this.onClose = function () {};
}

export default Modal;
