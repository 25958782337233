/**
 * Build Selector Attribute - Wraps attribute with square brackets.
 *
 * @param attr
 * @returns {string}
 */
function buildSelectorAttribute (attr) {
  return '[' + attr + ']';
}

export default buildSelectorAttribute;
