import $ from 'jquery';

window.jQuery(document).bind('gform_confirmation_loaded', function(event, formId){
  if (formId != 6) {
    let $confirmationWrapper = $('#gform_confirmation_wrapper_' + formId);
    let container = $confirmationWrapper.closest('[data-gravity-confirmation-container]');
  
    if (container.length > 0) {
      $confirmationWrapper.find('.type--yellow').css('color', 'white');
      $('[data-gravity-confirmation]').replaceWith($confirmationWrapper);
      $confirmationWrapper.prepend('<h2 class="type--white margin-bottom--content">Get in touch!</h2>');
    }
  }
});
