/**
 * Share
 *
 */

const moduleAttribute = 'data-share';

var socialNetworks = [
  {
    name: 'facebook',
    shareAction: function () {
      var url = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href;

      window.open(url, "pop", "width=600, height=400, scrollbars=no");
    }
  },
  {
    name: 'twitter',
    shareAction: function () {
      var url = 'https://twitter.com/intent/tweet?source=fbapp&text="' + window.location.href + '"';
      window.open(url, 'Tweet', 'status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=0,height=530,width=700');
    }
  },
  {
    name: 'gplus',
    shareAction: function () {
      var url = 'https://plus.google.com/share?url="' + window.location.href + '"';
      window.open(url);
    }
  },
  {
    name: 'linkedin',
    shareAction: function () {
      var url = 'https://www.linkedin.com/cws/share?url="' + window.location.href + '"';
      window.open(url);
    }
  },
  {
    name: 'pinterest',
    shareAction: function () {
      var url = 'http://pinterest.com/pin/create/button/?url="' + window.location.href + '"&media=""&description=""';
      window.open(url);
    }
  }
];

function Share() {
  function initialize() {
    for (var i = 0; i < socialNetworks.length; i++) {
      let socialNetwork = socialNetworks[i];
      enableClickEvents(socialNetwork);
    }
  }

  function enableClickEvents(socialNetwork) {
    let buttons = document.querySelectorAll(getSelector(socialNetwork.name));

    for (var i = 0; i < buttons.length; i++) {
      let button = buttons[i];

      button.addEventListener('click', function (e) {
        e.preventDefault();
        socialNetwork.shareAction();
      });
    }
  }

  function getSelector(attribute) {
    return '[' + moduleAttribute + '="' + attribute + '"]';
  }

  initialize();
}

var share = new Share();

export default share;
