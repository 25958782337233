/**
 * Globals
 *
 */

let wpAdminBar = document.querySelector('#wpadminbar');
let header = document.querySelector('#header');

let globals= {
  breakpoints: {
    xs: 480,
    sm: 768,
    md: 1025,
    lg: 1250
  },
  headerHeight: (header !== null) ? header.offsetHeight : 0,
  wpAdminHeight: (wpAdminBar !== null) ? wpAdminBar.offsetHeight : 0
};

export default globals;
