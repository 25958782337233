import utilities from '../utilities/utilities.js';
import CustomEvent from './event.js';

const transitionEndAttr = 'data-event-transition-end';
let elements = document.querySelectorAll(utilities.buildSelectorAttribute(transitionEndAttr));

if (elements !== null) {
  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    let customEvent = new CustomEvent(element.getAttribute(transitionEndAttr));

    document.addEventListener(utilities.getTransitionEvent(element), function() {
      customEvent.trigger();
    });
  }
}
