import utilities from './../utilities/utilities.js';

const overlayLayer = document.querySelectorAll('[data-overlay-layer]')[0];
let open = false;

document.addEventListener('openOverlay', function () {
  open = (!open);
  overlayLayer.classList.add('overlayLayer--open');
  overlayLayer.classList.add('overlayLayer--transitionEnd');
});

document.addEventListener('closeOverlay', function () {
  open = (!open);
  overlayLayer.classList.remove('overlayLayer--open');
});

let transitionEvent = utilities.getTransitionEvent(overlayLayer);

document.addEventListener(transitionEvent, function () {
  if (!open) {
    overlayLayer.classList.remove('overlayLayer--transitionEnd');
  }
});
