/**
 *  Tabs.
 *
 */

import BreakpointRange from './breakpoint-range.js';
import utilities from '../utilities/utilities.js';

const tabsParentAttribute = 'data-tabs-parent';
const tabsButtonAttribute = 'data-tabs-button';
const tabsTabAttribute = 'data-tabs-tab';
const tabsEnabledClass = 'tabs--enabled';
const tabsButtonActivatedClass = 'tabs__button--active';
const tabsTabOpenClass = 'tabs__tab--open';
const tabsNextTabAttribute = 'data-tabs-next-tab';
const tabsResetClassAttribute = 'data-reset-class';
const tabsScrollOnChangeAttribute =  'data-tabs-scroll-on-change';

let TabsComponents = [];

function TabsComponent (parent) {
  let breakpointRange = null;
  let buttons = parent.querySelectorAll(utilities.buildSelectorAttribute(tabsButtonAttribute));
  let tabs = parent.querySelectorAll(utilities.buildSelectorAttribute(tabsTabAttribute));
  let currentTab = 0;
  let tabsScrollOnChange = parent.hasAttribute(tabsScrollOnChangeAttribute);

  let range = parent.getAttribute(tabsParentAttribute);
  let hasRange = (range !== '');

  if (hasRange) {
    breakpointRange = new BreakpointRange(range.split(' '));

    document.addEventListener('BreakpointChange', function () {
      (breakpointRange.isOnRange()) ? this.enableTabs() : this.disableTabs();
    }.bind(this));
  } else {
    this.enableTabs();
  }

  enableClickEvents();
  enableNextTabEvents();

  /**
   * Public function - enables tab functionality.
   */
  this.enableTabs = function () {
    let resetClassElement = parent.querySelector('.tabs__tab--open' + utilities.buildSelectorAttribute(tabsResetClassAttribute));
    parent.classList.add(tabsEnabledClass);

    if (resetClassElement) {
      resetClassElement.classList.add(resetClassElement.getAttribute(tabsResetClassAttribute));
    }
  };

  /**
   * Public function - disables tab functionality.
   */
  this.disableTabs = function () {
    parent.classList.remove(tabsEnabledClass);

    let resetClassElements = parent.querySelectorAll(utilities.buildSelectorAttribute(tabsResetClassAttribute));

    for (let i = 0; i < resetClassElements.length; i++) {
      let resetClassElement = resetClassElements[i];

      resetClassElement.classList.add(resetClassElement.getAttribute(tabsResetClassAttribute));
    }
  };

  /**
   * Open Tab - Private function, opens a tab.
   *
   * @param id
   */
  function openTab(id = 0) {
    for (let i = 0; i < tabs.length; i++) {
      if (i === id) {
        let resetClassElement = utilities.getClosest(tabs[i], utilities.buildSelectorAttribute(tabsResetClassAttribute));

        tabs[i].classList.add(tabsTabOpenClass);

        if (resetClassElement !== null) {
          resetClassElement.classList.add(resetClassElement.getAttribute(tabsResetClassAttribute));
        }
      } else {
        let resetClassElement = utilities.getClosest(tabs[i], utilities.buildSelectorAttribute(tabsResetClassAttribute));

        tabs[i].classList.remove(tabsTabOpenClass);

        if (resetClassElement !== null) {
          resetClassElement.classList.remove(resetClassElement.getAttribute(tabsResetClassAttribute));
        }
      }
    }
  }

  /**
   * Activate Tabs Button - Private function, activates a tabs button.
   *
   * @param id
   */
  function activateTabsButton(id = 0) {
    for (let i = 0; i < buttons.length; i++) {
      if (i === id) {
        buttons[i].classList.add(tabsButtonActivatedClass);
      } else {
        buttons[i].classList.remove(tabsButtonActivatedClass);
      }
    }
  }

  /**
   * Private function - enables button click events.
   */
  function enableClickEvents () {
    for (let i = 0; i < buttons.length; i++) {
      let button = buttons[i];

      button.addEventListener('click', function(e) {
        e.preventDefault();
        goToTab(i);
      });
    }
  }

  /**
   * Go to tab - Opens tab with the given index.
   *
   * @param i
   */
  function goToTab(i) {
    setTab(i);
    openTab(i);
    activateTabsButton(i);
  }

  /**
   * Private function - sets tab index.
   *
   * @param i
   */
  function setTab(i) {
    currentTab = i;
  }

  /**
   * Enable next events - enables next events.
   *
   */
  function enableNextTabEvents() {
    let nextButtons = parent.querySelectorAll(utilities.buildSelectorAttribute(tabsNextTabAttribute));

    for (let i = 0; i < nextButtons.length; i++) {
      let nextButton = nextButtons[i];

      nextButton.addEventListener('click', function(e) {
        e.preventDefault();
        goToTab(currentTab + 1);

        if (tabsScrollOnChange) {
          let scrollDistance = utilities.getCoordinates(parent).top;
          let offset = parseInt(parent.getAttribute(tabsScrollOnChangeAttribute));
          let distance = scrollDistance + offset;

          utilities.scrollAnimation(distance)
        }
      });
    }
  }
}

function autoInit () {
  let tabsComponentParents = document.querySelectorAll(utilities.buildSelectorAttribute(tabsParentAttribute));

  for (let i = 0; i < tabsComponentParents.length; i++) {
    let parent = tabsComponentParents[i];

    TabsComponents.push(new TabsComponent(parent));
  }
}

autoInit();
