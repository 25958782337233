/**
 *  Cover
 *
 */

class Cover {
  constructor() {
    this.researchElements();
    this.addListeners();
  }

  researchElements() {
    this.elements = document.querySelectorAll('[data-cover="container"]');
  }

  addListeners() {
    if (document.addEventListener) {
      window.addEventListener('resize', function () {
        this.applyCoverToElements();
      }.bind(this));

      document.addEventListener('measureCover', function (e) {
        let element = e.data.slides[e.data.snapIndex];
        let isCoverContainer = (element.getAttribute('data-cover') === 'container');

        if (isCoverContainer) {
          let media = element.querySelector('[data-cover="media"]');
          let isCoverMeasured = media.classList.contains('cover--measured');

          if (!isCoverMeasured) {
            this.applyCoverToElement(element);
          }
        }

      }.bind(this));

      document.addEventListener('applyCoverToElement', function (e) {
        let element = e.data;

        this.applyCoverToElement(element);
      }.bind(this));

      this.applyCoverToElements();
    } else {
      throw 'NO ADD EVENT LISTENER EVENT PLEASE UPGRADE YOUR BROWSER';
    }
  }

  applyCoverToElements() {
    for (let key = 0; key < this.elements.length; key++) {
      let element = this.elements[key];
      this.applyCoverToElement(element);
    }
  }

  applyCoverToElement(element) {
    let media = element.querySelector('[data-cover="media"]');

    if (media === null || element === null) {
      return false;
    }

    media.oncanplay = function () {
      this.setCoverClasses(element, media);
    }.bind(this);

    this.setCoverClasses(element, media);
  }

  setCoverClasses(element, media) {
    let elementRatio = this.getRatio(element);
    let mediaSetRatio = media.querySelector('[data-cover-ratio]');
    let mediaRatio = (mediaSetRatio === null) ? this.getRatio(media) : mediaSetRatio.getAttribute('data-cover-ratio');
    let elementOrientation = this.getOrientation(elementRatio);

    this.resetClasses(media);
    this.resetClasses(element);

    if (elementOrientation === 'portrait') {
      element.classList.add('cover--portrait');
    } else {
      element.classList.add('cover--landscape');
    }

    if (mediaRatio > elementRatio) {
      media.classList.add('cover__element--wide');
    } else {
      media.classList.add('cover__element--narrow');
    }

    media.classList.add('cover--measured');
  }

  setMeasuredClasses() {
    for (let key = 0; key < this.elements.length; key++) {
      let element = this.elements[key];
      let media = element.querySelector('[data-cover="media"]');

      media.classList.add('cover--measured');
    }
  }

  resetClasses(element) {
    element.classList.remove('cover--landscape');
    element.classList.remove('cover--portrait');
    element.classList.remove('cover__element--narrow');
    element.classList.remove('cover__element--wide');
    element.classList.remove('cover--measured');
  }

  getRatio(element) {
    return element.offsetWidth / element.offsetHeight;
  }

  getOrientation(ratio) {
    return (ratio >= 1) ? 'landscape' : 'portrait';
  }
}

export default Cover;
