/*
  Project: Archiblox
  Author: Konstantinos Kataras
 */

import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/bgset/ls.bgset.js';
import './modules/overlayLayer.js';
import ResponsiveBackground from './modules/responsive-background.js';
import BreakpointTracker from './modules/breakpoint-tracker.js';
import Menu from './modules/menu.js';
import AutoHeight from './modules/auto-height.js';
import Cover from './modules/cover.js';
import Swiper from './modules/swiper.js';
import './modules/scroll-next.js';
import './modules/animations.js';
import Switch from './modules/Switch.js';
import './modules/tabs.js';
import Filter from './modules/filter.js';
import './modules/sharing.js';
import './modules/select.js';
import './modules/gallery.js';
import './modules/analytics.js';
import './modules/scroll-to-on-load.js';
import './modules/transition-end.js';
import './modules/scroll-to';
import './modules/scroll-after-transition';
import './modules/rotate-image';
import loadScaleModals from './modules/scale-modal';
import loadImageModal from './modules/image-modal';
import './modules/sticky.js';
import './modules/gravity-forms';
import './modules/reveal-form-blocks';
import './modules/scroll-menu-link';
import PopupNew from './modules/popup';

new Switch();
new Cover();
new BreakpointTracker();
new ResponsiveBackground();
new Menu();
new Filter();
new PopupNew();

import Autoplay from './modules/autoplay.js';
new Autoplay();

Swiper.initSplitSectionSwiper();
Swiper.initInstagramSwiper();
Swiper.initDesignTypeSwiper();
Swiper.initQuoteListSwiper();
Swiper.initSliderSwiper();
Swiper.initOneSlideSlider();
Swiper.initHeroSectionSwiper();

AutoHeight.init();
loadScaleModals();
loadImageModal();
