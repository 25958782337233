/**
 * Custom Event.
 *
 */

class CustomEvent {
  constructor(eventName, element, data) {
    this.event = null;
    this.eventName = eventName;
    this.element = element || false;
    this.data = data;
    this.createEvent();
  }

  createEvent() {
    if (document.createEvent) {
      this.event = document.createEvent("HTMLEvents");
      this.event.initEvent(this.eventName, true, true);

    } else {
      if (this.element) {
        this.event = this.element.createEventObject();
        this.event.eventType = this.eventName;
      } else {
        this.event = document.createEventObject();
        this.event.eventType = this.eventName;
      }
    }

    this.event.eventName = this.eventName;
    this.event.data = this.data;
  }

  trigger(data) {
    this.event.data = data;

    if (document.createEvent) {
      if (this.element) {
        this.element.dispatchEvent(this.event);
      } else {
        document.dispatchEvent(this.event);
      }
    } else {
      if (this.element) {
        this.element.fireEvent("on" + this.event.eventType, this.event);
      } else {
        document.fireEvent("on" + this.event.eventType, this.event);
      }
    }
  }
}

export default CustomEvent;
