/**
 *  Switch - A module for turning on off things.
 *  @author: Konstantinos Kataras.
 */

import utilities from './../utilities/utilities.js';
import BreakpointRange from './breakpoint-range.js';
import CustomEvent from './event.js';
import ClickOut from './click-out';

const autoSwitchTriggerSelector = '[data-switch="trigger"]';
const autoSwitchParentSelector = '[data-switch="parent"]';
const assignParentSelector = 'data-switch-parent';
const defaultTriggerClass = 'js-switch-on';
const defaultResetAttribute = 'data-switch-reset';
const transitionAttribute = 'data-switch-transition-end-class';
const extraClassAttribute = 'data-switch-extra-class';
const clearSiblingsAttribute = 'data-switch-clear-siblings';
const customEventAttribute = 'data-switch-custom-event';
const transitionElementAttribute = 'data-trigger-transition';
const clickOutReverseAttribute = 'data-trigger-click-out';
const clickOutExceptionElementAttribute = 'data-trigger-click-out-exception';
const resetTriggersAttribute = 'data-switch-reset-triggers';

/**
 * Generator of the switch object.
 *
 * @param options
 * @constructor
 */
function Switch(options) {
  let element = false;
  let triggerClass = defaultTriggerClass;
  let parentSelector = false;
  let autoSwitchTriggers;

  if (typeof options !== 'undefined') {
    element = options.element;
    triggerClass = options.customClass || defaultTriggerClass;
    parentSelector = options.parentSelector;
  }

  /**
   * Construct - Constructor function for Switch class.
   *
   */
  function construct() {
    if (typeof options === 'undefined') {
      autoSwitchTriggers = document.querySelectorAll(autoSwitchTriggerSelector);
      enableClickEvents(autoSwitchTriggers);
    } else {
      let parent = (parentSelector) ? utilities.getClosest(element, parentSelector) : false;
      enableClickEvent(element, parent);
    }
  }

  /**
   * Clears classes from siblings based on
   *
   * @param identification
   */
  function clearSiblings(identification) {
    for (let key = 0; key < autoSwitchTriggers.length; key++) {
      let element = autoSwitchTriggers[key];

      if (element.clearSiblings && element.clearSiblings === identification && element.switchState) {
        clearElement(element);
      }
    }
  }

  /**
   * Clear Element - Clears element from classes.
   *
   * @param element
   */
  function clearElement(element) {
    let affectedElement = getAffectedElement(element);

    affectedElement.classList.remove(triggerClass);
    element.switchState = false;

    if (element.extraClass) {
      affectedElement.classList.remove(element.extraClass);
    }
  }

  /**
   * Enable Click Events - Enables switch click events for a list of elements.
   *
   * @param elements
   */
  function enableClickEvents(elements) {
    for (let key = 0; key < elements.length; key++) {
      let element = elements[key];
      let parent = getParent(element);
      let reset = element.hasAttribute(defaultResetAttribute);

      element.customEventName = (element.hasAttribute(customEventAttribute)) ? element.getAttribute(customEventAttribute) : false;
      element.clearSiblings = (element.hasAttribute(clearSiblingsAttribute)) ? element.getAttribute(clearSiblingsAttribute) : false;
      element.transitionClass = (element.hasAttribute(transitionAttribute)) ? element.getAttribute(transitionAttribute) : false;
      element.extraClass = (element.hasAttribute(extraClassAttribute)) ? element.getAttribute(extraClassAttribute) : false;
      element.switchState = false;

      if (element.customEventName) {
        element.customOpenEvent = new CustomEvent('open' + element.customEventName);
        element.customCloseEvent = new CustomEvent('close' + element.customEventName);
      }

      if (reset) {
        element.breakpointRange = new BreakpointRange(element.getAttribute(defaultResetAttribute).split(' '));
      } else {
        element.breakpointRange = new BreakpointRange(['xs', 'sm', 'md', 'lg', 'xl']);
      }

      if (reset) {
        resetElement(element, parent);
      }

      enableClickEvent(element, parent);
    }
  }

  /**
   * Reset Element - Resets element on when out of range.
   *
   * @param element
   * @param parent
   */
  function resetElement(element, parent = false) {
    let affectedElement = (parent) ? parent : element;

    document.addEventListener('BreakpointChange', function () {
      if (!element.breakpointRange.isOnRange()) {
        clearElement(element);

        if (element.customEventName) {
          element.customCloseEvent.trigger();
        }

        if (element.transitionClass) {
          affectedElement.classList.remove(element.transitionClass);
        }
      }
    });
  }

  /**
   * Get Affected Element - Returns the affected element.
   *
   * @param element
   * @returns {*}
   */
  function getAffectedElement (element) {
    let parent = getParent(element);

    return (parent) ? parent : element;
  }

  /**
   * Get parent - Returns the parent of the switch element.
   *
   * @param element
   * @returns {*}
   */
  function getParent (element) {
    let parent = utilities.getClosest(element, autoSwitchParentSelector);
    let assignedParent = getAssignedParent(element);

    parent = (parent !== null) ? parent : false;
    parent = (assignedParent) ? assignedParent : parent;

    return parent;
  }

  /**
   * Get Assigned Parent - Returns the assigned parent.
   *
   * @param element
   * @returns {boolean}
   */
  function getAssignedParent (element) {
    return (element.hasAttribute(assignParentSelector)) ? utilities.getClosest(element, element.getAttribute(assignParentSelector)) : false;
  }

  /**
   * Enable Click Event - Enables the click event for an element.
   *
   * @param element
   * @param parent
   */
  function enableClickEvent(element, parent = false) {
    let affectedElement = (parent) ? parent : element;
    let transitionEndEvent = utilities.getTransitionEvent(element);
    let clickOutElement = element.hasAttribute(clickOutReverseAttribute);
    let clickOut;

    if (clickOutElement) {
      clickOut = new ClickOut({
        selector: (parent) ? assignParentSelector : autoSwitchTriggerSelector,
        exceptionElement: affectedElement.querySelector('[' + clickOutExceptionElementAttribute + ']'),
        exceptionSelector: '[' + clickOutExceptionElementAttribute + ']',
        callback: function () {
          if (element.breakpointRange.isOnRange()) {
            toggleSwitchState(element, affectedElement);
            clickOut.disableClickOut();
          }
        }
      });

      clickOut.disableClickOut();
    }

    element.addEventListener('click', function (e) {
      e.preventDefault();

      if (element.breakpointRange.isOnRange()) {
        if (!clickOutElement) {
          toggleSwitchState(element, affectedElement);
        } else if (!element.switchState) {
          clickOut.enableClickOut();
          toggleSwitchState(element, affectedElement);
        }
      }
    }.bind(this));

    let transitionElement = affectedElement.querySelectorAll('[' + transitionElementAttribute + ']');

    if (transitionElement.length > 0) {
      transitionElement[0].addEventListener(transitionEndEvent, function() {
        if (element.transitionClass && !element.switchState && element.breakpointRange.isOnRange()) {
          affectedElement.classList.remove(element.transitionClass);
        }
      });
    }
  }

  /**
   * Toggle Switch State - Toggles the switch state of an element.
   *
   * @param element - The element that the click event takes place upon.
   * @param affectedElement - The affected element from the click event action.
   */
  function toggleSwitchState(element, affectedElement) {
    if (element.clearSiblings && !element.switchState) {
      clearSiblings(element.clearSiblings);
    }

    if (element.switchState && element.customEventName) {
      element.customCloseEvent.trigger();
    } else if (element.customEventName) {
      element.customOpenEvent.trigger();
    }

    if(!element.switchState && element.classList.contains('is-accordion-element') && window.jQuery) {
      window.jQuery('[data-switch="parent"].js-switch-on > [data-switch="trigger"]').trigger('click');
    }

    let resetTriggersParent = utilities.getClosest(element, '[data-switch-reset-triggers]');

    if (resetTriggersParent && !element.switchState) {
      let resetTriggers = resetTriggersParent.querySelectorAll(autoSwitchTriggerSelector);

      for (let i = 0; i < resetTriggers.length; i+=1) {
        let resetElement = resetTriggers[i];
        let affectedElement = getAffectedElement(resetElement);

        if (!element.isSameNode(resetElement)) {
          if (resetElement.switchState === true) {
            affectedElement.classList.remove(triggerClass);
            resetElement.switchState = false;

            if (resetElement.extraClass) {
              affectedElement.classList.toggle(resetElement.extraClass);
            }

            if (resetElement.transitionClass && resetElement.switchState) {
              affectedElement.classList.add(resetElement.transitionClass);
            }
          }
        }
      }
    }

    element.switchState = (!element.switchState);
    affectedElement.classList.toggle(triggerClass);

    if (element.extraClass) {
      affectedElement.classList.toggle(element.extraClass);
    }

    if (element.transitionClass && element.switchState) {
      affectedElement.classList.add(element.transitionClass);
    }
  }

  construct();
}

export default Switch;
