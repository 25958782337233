import utilities from '../utilities/utilities.js';
import CustomEvent from './event';

const SCROLL_MENU_LINK_ATTRIBUTE = 'data-scroll-menu-link';

let scrollMenuLinks = document.querySelectorAll(utilities.buildSelectorAttribute(SCROLL_MENU_LINK_ATTRIBUTE));
let path = window.location.pathname;
let menuCloseEvent = new CustomEvent('closeMainMenu');

for (let i = 0; i < scrollMenuLinks.length; i += 1) {
  let linkElement = scrollMenuLinks[i];
  let linkPath = linkElement.getAttribute('href').split('#')[0];

  linkElement.addEventListener('click', function () {
    if (linkPath === path) {
      menuCloseEvent.trigger();
    }
  });
}
