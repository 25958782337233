import $ from 'jquery';

/* eslint no-magic-numbers: 0 */

function PopupNew() {
  let projectPopup = $('.js-project-popup');
  const projectPopupOverlay = $('.js-project-popup-overlay');
  let popup = $('.js-popup');
  const popupOverlay = $('.js-popup-overlay');

  if (!popup.length && !projectPopup.length) {
    return;
  }

  const delay = popup.data('delay-time') || 10000;
  const cookie = popup.data('set-cookie') || 'no';
  let brochureFileId;
  let docType;

  setPopup();

  window.jQuery(document).bind('gform_confirmation_loaded', function(event, formId){
    setTimeout(function() {
      const popup = $('.js-popup');
      const projectPopup = $('.js-project-popup');

      if (popup.length) {
        popup.find('.gform_confirmation_message').find('h3').removeClass('type--white margin-bottom--content-tight transition-fade-in-up transition-delay-2');
      }

      if (projectPopup.length) {
        console.log(brochureFileId, docType);
        //projectPopup.find('.gform_confirmation_message').find('h3').removeClass('type--white margin-bottom--content-tight transition-fade-in-up transition-delay-2');
        generateBrochureFile(projectPopup, brochureFileId, docType);
      }
    }, 100);
  });

  function setPopup() {
    setTimeout(function() {
      alignHrs();

      openPopup();
    }, delay);

    projectPopupInit();
  }

  function alignHrs() {
    popup.find('.popup__content hr').each(function(index, el) {
      const self = $(el);

      if (self.prev().css('text-align') === 'center') {
        self.wrap('<div style="text-align:center;"></div>')
      }
    });
  }

  function openPopup() {
    popupOverlay.fadeIn(200);

    popup
      .show()
      .css({
        opacity: 0,
        display: 'flex',
        top: '-30px',
      })
      .stop()
      .animate(
      {
        opacity: 1,
        top: 0,
      }, 300
    );

    closePopup();
  }

  function closePopup() {
    const closeBtn = popup.find('.js-popup-close');

    closeBtn.on('click', function() {
      setCookie();
      popup.stop().animate(
        {
          opacity: 0,
          top: '-30px',
        },
        300,
        function() {
          popup.hide();
          popupOverlay.fadeOut(200);
        }
      );
    });
  }

  function setCookie() {
    if (cookie === 'yes') {
      const data = {
        action: 'set_cookie',
        nonce: x5script.nonce,
      };

      $.post(x5script.ajaxurl, data)
        .done(function() {})
        .fail(function() {});
    }
  }

  function projectPopupInit() {
    const planBtn = $('.js-plan-btn');
    let planBtnContainer = $('.js-plan-btn-container');
    planBtnContainer.css('display', 'inline-block');

    if (planBtn.length) {
      planBtn.on('click', function(e) {
        const self = $(e.currentTarget);
        planBtnContainer = self.parent();

        if (self.attr('href').slice(0,1) === '#') {
          e.preventDefault();
          brochureFileId = self.attr('href').slice(1);
          docType = planBtnContainer.attr('data-doc-type');
          const signedUp = projectPopup.attr('data-signed-up');
          console.log(brochureFileId, docType);

          if (signedUp == 1) {
            generateBrochureFile(projectPopup, brochureFileId, docType);
          }
          closeProjectPopup();
          openProjectPopup();
        }
      });
    }
  }

  function generateBrochureFile(popup, fileId, docType) {
    const popupContent = popup.find('.js-project-popup-content');
    popupContent.html(`<div class="project-popup__download">Generating ${docType}... <img src="${x5script.loader}" alt="generating..."/></div>`);

    const data = {
      action: 'get_brochure_file',
      nonce: x5script.nonce,
      brochure_file_id: fileId,
    };

    $.post(x5script.ajaxurl, data)
      .done(function(response) {
        if (response[0] == 'ok') {
          setTimeout(function() {
            popupContent.html(`<div class="project-popup__download js-project-popup-download"><a href="#" class="button js-plan-btn  button--secondary" title="Download brochure"><div class="button__text button__text--non_breakable">Download ${docType}</div></a></div>`);

            popupContent.find('.js-project-popup-download').on('click', function(e) {
              e.preventDefault();
              window.open(response[1], '_blank');
              window.focus();
              window.location.reload();
            });
          }, 1000);
        }
      })
      .fail(function() {});
  }

  function openProjectPopup() {
    projectPopupOverlay.fadeIn(200);

    projectPopup
      .show()
      .css({
        opacity: 0,
        display: 'flex',
        top: '-30px',
      })
      .stop()
      .animate(
      {
        opacity: 1,
        top: 0,
      }, 300
    );

    closeProjectPopup();
  }

  function closeProjectPopup() {
    const closeBtn = projectPopup.find('.js-popup-close');

    closeBtn.on('click', function() {
      setCookie();
      projectPopup.stop().animate(
        {
          opacity: 0,
          top: '-30px',
        },
        300,
        function() {
          projectPopup.hide();
          projectPopupOverlay.fadeOut(200);
        }
      );
    });
  }
}

export default PopupNew;

// class Popup {
//   constructor() {
//     this.projectPopup = $('.js-project-popup');
//     this.projectPopupOverlay = $('.js-project-popup-overlay');
//     this.popup = $('.js-popup');
//     this.popupOverlay = $('.js-popup-overlay');

//     if (!this.popup.length && !this.projectPopup.length) {
//       return;
//     }

//     this.delay = this.popup.data('delay-time') || 10000;
//     this.cookie = this.popup.data('set-cookie') || 'no';

//     this.setPopup();

//     const that = this;

//     window.jQuery(document).bind('gform_confirmation_loaded', function(event, formId){
//       setTimeout(() => {
//         const popup = $('.js-popup');
//         const projectPopup = $('.js-project-popup');

//         if (popup.length) {
//           popup.find('.gform_confirmation_message').find('h3').removeClass('type--white margin-bottom--content-tight transition-fade-in-up transition-delay-2');
//         }

//         if (projectPopup.length) {
//           //projectPopup.find('.gform_confirmation_message').find('h3').removeClass('type--white margin-bottom--content-tight transition-fade-in-up transition-delay-2');
//           Popup.generateBrochureFile(projectPopup, that.brochureFileId, that.docType);
//         }
//       }, 100);
//     });
//   }

//   alignHrs() {
//     this.popup.find('.popup__content hr').each((index, el) => {
//       const self = $(el);

//       if (self.prev().css('text-align') === 'center') {
//         self.wrap('<div style="text-align:center;"></div>')
//       }
//     });
//   }

//   setPopup() {
//     setTimeout(() => {
//       this.alignHrs();

//       this.openPopup();
//     }, this.delay);

//     this.projectPopupInit();
//   }

//   openPopup() {
//     this.popupOverlay.fadeIn(200);

//     this.popup
//       .show()
//       .css({
//         opacity: 0,
//         display: 'flex',
//         top: '-30px',
//       })
//       .stop()
//       .animate(
//       {
//         opacity: 1,
//         top: 0,
//       }, 300
//     );

//     this.closePopup();
//   }

//   closePopup() {
//     const closeBtn = this.popup.find('.js-popup-close');

//     // this.popup.on('click', e => {
//     //   if ($(e.target).hasClass('js-popup')) {
//     //     closeBtn.trigger('click');
//     //   }
//     // });

//     closeBtn.on('click', () => {
//       this.setCookie();
//       this.popup.stop().animate(
//         {
//           opacity: 0,
//           top: '-30px',
//         },
//         300,
//         () => {
//           this.popup.hide();
//           this.popupOverlay.fadeOut(200, () => {
//             // if (this.popup.find('.gform_confirmation_message').length) {
//             //   this.popupContent.prepend(this.formCloneHtml);
//             //   $(document).trigger('gform_post_render', [this.formId, 0]);
//             //   this.popup.find('.gform_confirmation_message').remove();
//             // }

//             // if (this.popup.find('.validation_error').length) {
//             //   this.popup.find('form')[0].reset();
//             //   this.popup.find('.val_summary').remove(); // the validation summary message
//             //   this.popup.find('.validation_error').remove(); // the validation summary message
//             //   this.popup
//             //     .find('li.gfield_error div.validation_message')
//             //     .remove(); // remove the validation meesages
//             //   this.popup.find('li.gfield_error').removeClass('gfield_error'); // remove the validation classes.
//             // }
//           });
//         }
//       );
//     });
//   }

//   setCookie() {
//     if (this.cookie === 'yes') {
//       const data = {
//         action: 'set_cookie',
//         nonce: x5script.nonce,
//       };

//       $.post(x5script.ajaxurl, data)
//         .done(() => {})
//         .fail(() => {});
//     }
//   }

//   projectPopupInit() {
//     this.planBtn = $('.js-plan-btn');
//     this.planBtnContainer = $('.js-plan-btn-container');
//     this.planBtnContainer.css('display', 'inline-block');

//     if (this.planBtn.length) {
//       this.planBtn.on('click', e => {
//         const self = $(e.currentTarget);
//         const planBtnContainer = self.parent();

//         if (self.attr('href').slice(0,1) === '#') {
//           e.preventDefault();
//           this.brochureFileId = self.attr('href').slice(1);
//           this.docType = planBtnContainer.attr('data-doc-type');
//           const signedUp = this.projectPopup.attr('data-signed-up');

//           if (signedUp == 1) {
//             Popup.generateBrochureFile(this.projectPopup, this.brochureFileId, this.docType);
//           }
//           this.closeProjectPopup();
//           this.openProjectPopup();
//         }
//       });
//     }
//   }

//   static generateBrochureFile(popup, fileId, docType) {
//     const popupContent = popup.find('.js-project-popup-content');
//     popupContent.html(`<div class="project-popup__download">Generating ${docType}... <img src="${x5script.loader}" alt="generating..."/></div>`);

//     const data = {
//       action: 'get_brochure_file',
//       nonce: x5script.nonce,
//       brochure_file_id: fileId,
//     };

//     $.post(x5script.ajaxurl, data)
//       .done(response => {
//         if (response[0] == 'ok') {
//           setTimeout(() => {
//             popupContent.html(`<div class="project-popup__download js-project-popup-download"><a href="#" class="button js-plan-btn  button--secondary" title="Download brochure"><div class="button__text button__text--non_breakable">Download ${docType}</div></a></div>`);

//             popupContent.find('.js-project-popup-download').on('click', e =>{
//               e.preventDefault();
//               window.open(response[1], '_blank');
//               window.focus();
//               window.location.reload();
//             });
//           }, 1000);
//         }
//       })
//       .fail(() => {});
//   }

//   openProjectPopup() {
//     this.projectPopupOverlay.fadeIn(200);

//     this.projectPopup
//       .show()
//       .css({
//         opacity: 0,
//         display: 'flex',
//         top: '-30px',
//       })
//       .stop()
//       .animate(
//       {
//         opacity: 1,
//         top: 0,
//       }, 300
//     );

//     this.closeProjectPopup();
//   }

//   closeProjectPopup() {
//     const closeBtn = this.projectPopup.find('.js-popup-close');

//     closeBtn.on('click', () => {
//       this.setCookie();
//       this.projectPopup.stop().animate(
//         {
//           opacity: 0,
//           top: '-30px',
//         },
//         300,
//         () => {
//           this.projectPopup.hide();
//           this.projectPopupOverlay.fadeOut(200);
//         }
//       );
//     });
//   }
// }

// export default Popup;
