/**
 *  Scale Modal
 *
 */

import $ from 'jquery';
import Modal from './modal';
import BreakpointRange from './breakpoint-range';

const SCALE_MODAL_PARENT = '[data-scale-modal="parent"]';
const SCALE_MODAL_ACTION = '[data-scale-modal="action"]';
const SCALE_MODAL_ELEMENT = '[data-scale-modal="modal"]';
const SCALE_MODAL_LEFT = '[data-scale-modal="left"]';
const SCALE_MODAL_RIGHT = '[data-scale-modal="right"]';
const SCALE_MODAL_IMAGE = '[data-scale-modal="image"]';
const SCALE_MODAL_IMAGE_CONTAINER = '[data-scale-modal="image-container"]';
const SCALE_MODAL_CONTENT = '[data-scale-modal="content"]';
const SCALE_MODAL_CONTENT_CONTAINER = '[data-scale-modal="content-container"]';

let scaleModals = $(SCALE_MODAL_PARENT);

function loadScaleModals () {
  scaleModals.each(function () {
    let parent = $(this);
    let modalElement = parent.find(SCALE_MODAL_ELEMENT);
    let actions = parent.find(SCALE_MODAL_ACTION);
    let actionsLength = actions.length;
    let left = parent.find(SCALE_MODAL_LEFT);
    let right = parent.find(SCALE_MODAL_RIGHT);
    let modal = new Modal(modalElement, {
      clickOut: true
    });
    let imageContainer = modalElement.find(SCALE_MODAL_IMAGE_CONTAINER);
    let contentContainer = modalElement.find(SCALE_MODAL_CONTENT_CONTAINER);
    let currentElementIndex = 0;
    let breakpointRange = new BreakpointRange(['md', 'lg', 'xl']);

    actions.on('click', function () {
      let element = $(this);
      let index = element.index();

      if (breakpointRange.isOnRange()) {
        currentElementIndex = index;
        fillElement(index);
        modal.open();
      }
    });

    document.addEventListener('BreakpointChange', function () {
      if (!breakpointRange.isOnRange()) {
        modal.close();
      }
    });

    left.on('click', function () {
      if (currentElementIndex === 0) {
        currentElementIndex = actionsLength - 1;
      } else {
        currentElementIndex -= 1;
      }

      fillElement(currentElementIndex);
    });

    right.on('click', function () {
      if (currentElementIndex === actionsLength - 1) {
        currentElementIndex = 0;
      } else {
        currentElementIndex += 1;
      }

      fillElement(currentElementIndex);
    });

    function fillElement(index) {
      let actionElement = $(actions[index]);
      let image = actionElement.find(SCALE_MODAL_IMAGE).clone();
      let content = actionElement.find(SCALE_MODAL_CONTENT);

      imageContainer.html(image);
      contentContainer.html('');

      content.each(function () {
        let clone = $(this).html();
        contentContainer.append(clone);
      });
    }
  });
}

export default loadScaleModals;
