import $ from 'jquery';
import Modal from './modal.js';
import Swiper from 'swiper';
import CustomEvent from './event.js';

let $modals = $('[data-modal="modal"]');
let $projectGalleryModal = $('#project');

if ($projectGalleryModal.length > 0) {
  let youTubeApi = document.createElement('script');

  youTubeApi.src = 'http://www.youtube.com/iframe_api';
  document.body.appendChild(youTubeApi);

  window.onYouTubeIframeAPIReady = function() {

    $('.iframeVideo').each(function() {
      let src = this.getAttribute('data-src');
      let srcArray = src.split('/');
      let videoId = srcArray[srcArray.length - 1];

      let player = new YT.Player(this, {
        height: '360',
        width: '640',
        videoId: videoId
      });

      document.addEventListener('stopIframeVideos', function () {
        player.stopVideo();
      });
    });

    loadGalleries();
  };
} else {
  loadGalleries();
}

function loadGalleries() {
  $modals.each(function() {
    let $modal = $(this);
    let id = this.getAttribute('id');
    let $galleryOpenButton = $('[data-gallery-modal="' + id + '"]');
    let modal = new Modal($modal);
    let $videos = $modal.find('.modalGallery__video');
    let galleryTopElement = $modal.find('[data-modal-gallery-top]');
    let galleryBottomElement = $modal.find('[data-modal-gallery-bottom]');

    let galleryTop =  new Swiper(galleryTopElement, {
      slidesPerView: 1
    });

    let galleryBottom =  new Swiper(galleryBottomElement, {
      centeredSlides: true,
      slidesPerView: 'auto',
      touchRatio: 0.2,
      spaceBetween: 8,
      slideToClickedSlide: true,
      nextButton: '#' + id + ' .swiper-button-next',
      prevButton: '#' + id + ' .swiper-button-prev',
      onSlideChangeStart: function() {
        stopVideos($videos);
      }
    });

    modal.onClose = function () {
      stopVideos($videos);
    };

    galleryTop.params.control = galleryBottom;
    galleryBottom.params.control = galleryTop;

    $galleryOpenButton.on('click', function (e) {
      e.preventDefault();
      modal.setLoaded(true);
      modal.open();
    });

  });
}

let stopIframeVideos = new CustomEvent('stopIframeVideos');

function stopVideos($videos) {
  $videos.each(function () {
    if (typeof this.pause !== 'undefined') {
      let $button = $(this).parent().find('a');

      if ($button.hasClass('button--playing')) {
        $button.click();
      }
    }

    stopIframeVideos.trigger();
  });
}
