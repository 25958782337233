/**
 * Filter
 *
 */

import $ from 'jquery';
import Isotope from 'isotope-layout';
/**
 * Class Filter - filter object reads and initialises isotope or normal filters.
 */
class Filter {

  /**
   * Constructor function of the filter object.
   */
  constructor() {
    this.$actions = $('[data-filter-action]');
    this.$disconnectedActions = $('[data-filter-disconnected-action]');
    this.$openButtons = $('[data-filter-open]');
    this.$mobileFiltersAction = $('[data-filters-mobile]');
    this.$clearFilters = $('[data-filters-clear] a');
    this.filter = null;
    this.grid = document.querySelector('[data-grid]');
    this.jqueryFilterParent = document.querySelector('[data-filter-parent]');
    this.masterParent = document.querySelector('[data-filter-master-parent]');
    this.detached = [];
    this.allFiltersCleared = null;

    if (this.grid) {
      this.elements = this.grid;

      $(window).on('load', function() {
        this.isotope = new Isotope(this.elements, {
          itemSelector: '[data-grid-item]'
        });
      }.bind(this));

      this.initIsotopeEvents();
    } else if (this.jqueryFilterParent) {
      this.elements = $('[data-filter-element]');

      this.initNormalEvents();
    }

    if (this.$actions.length > 0) {
      this.renderFilter();
    }

    if (this.$mobileFiltersAction.length > 0) {
      let $filterList = $('.filter__list');

      this.$mobileFiltersAction.on('click', function () {
        $filterList.toggleClass('open');

        if ($filterList.hasClass('open')) {
          $('body').css('overflow', 'hidden');
        } else {
          $('body').css('overflow', '');
        }

        $('[data-filter-close]').click(function () {
          $filterList.removeClass('open');
          $('body').css('overflow', '');
        })
      });
    }

    this.$clearFilters.on('click', function(e) {
      e.preventDefault();
      $('[data-filter-disconnected-action="all"]').trigger('click');
    })

    this.bindResizeEvent();
  }

  /**
   * Init Isotope Events - Initialises click events for the filters with isotope functionality.
   *
   * @returns {Filter}
   */
  initIsotopeEvents() {
    this.$actions.on('click', function (e) {
      e.preventDefault();
      let $element = $(e.currentTarget);
      this.filter = $element.attr('data-filter-action');
      this.isotope.arrange({ filter: '[data-filter*="' + this.filter + '"]' });
      $element.closest('.filter').find('span').html($element.html());
      this.$actions.removeClass('active');
      $element.addClass('active');
      this.closeAllFilters();
    }.bind(this));

    this.$openButtons.on('click', function (e) {
      e.preventDefault();
      let $element = $(e.currentTarget);

      this.openFilters($element);
    }.bind(this));

    return this;
  }

  /**
   * Set filter - Sets the filters based on the submenu selection.
   *
   * @returns {Filter}
   */
  setFilter() {
    let allFiltersCleared = true;

    this.$filters.each(function (index) {
      let $filter = $(this.$filters.get(index));
      let taxonomy = $filter.attr('data-filter');
      let active = $filter.find('.active');
      let term = active.attr('data-filter-action');

      $filter.find('[data-filter-active-filter]').html('(' + active.html() + ')');

      if (this.masterParent) {
        $(this.masterParent).find('[data-filter-indicators] [data-filter-indicator="' + taxonomy + '"]').html(active.html());
      }

      this.filter[taxonomy] = term;

      if (term !== 'all' && typeof $filter.attr('data-filter-no-clear-state') === 'undefined') {
        allFiltersCleared = false;
      }

    }.bind(this));

    this.allFiltersCleared = allFiltersCleared;

    return this;
  }

  /**
   * Init Normal Events - Initialises the filter events for the non Isotope filters.
   *
   * @returns {Filter}
   */
  initNormalEvents() {
    this.filter = {};
    this.$filters = $('[data-filter]');
    this.setFilter();

    this.$actions.on('click', function (e) {
      e.preventDefault();

      this.setMixedClass($(e.currentTarget))
        .setFilter()
        .renderFilter()
        .closeAllFilters();
    }.bind(this));

    this.$disconnectedActions.on('click', function (e) {
      e.preventDefault();

      this.setDisconnectedActiveClass($(e.currentTarget))
        .setFilter()
        .renderFilter()
        .closeAllFilters();
    }.bind(this));

    this.$openButtons.on('click', function (e) {
      e.preventDefault();

      this.openFilters($(e.currentTarget));
    }.bind(this));

    return this;
  }

  /**
   * Set Active Class - Sets active class on the selected filter and clears from siblings.
   *
   * @param $element
   * @returns {Filter}
   */
  setActiveClass($element) {
    let attr = $element.closest('[data-filter]').attr('data-filter');
    let $sameFilters = $('[data-filter="' + attr + '"]');
    let term = $element.attr('data-filter-action');
    let $sameTermElements = $sameFilters.find('[data-filter-action="' + term +'"]');

    $sameFilters.find('.active').removeClass('active');
    $sameTermElements.addClass('active');

    return this;
  }

  setMixedClass($element) {
    if (this.masterParent) {
      let $parent = $element.closest('[data-filter]');
      let attr = $parent.attr('data-filter');
      let term = $element.attr('data-filter-action');
      let $disconnectedAction = $(this.masterParent).find('[data-filter-disconnected="' + attr + '"] [data-filter-disconnected-action="' + term + '"]');

      this.setDisconnectedActiveClass($disconnectedAction);
    }

    this.setActiveClass($element);

    return this;
  }

  /**
   * Set Disconnected Active Class - Sets active class to both the disconnected and connected action buttons.
   *
   * @param $element
   * @returns {Filter}
   */
  setDisconnectedActiveClass($element) {
    let $parent = $element.closest('[data-filter-disconnected]');
    let attr = $parent.attr('data-filter-disconnected');
    let $siblings = $parent.find('[data-filter-disconnected-action]');
    let term = $element.attr('data-filter-disconnected-action');
    let $connectedElement = $(this.masterParent).find('[data-filter="' + attr + '"] [data-filter-action="' + term + '"]');

    $siblings.removeClass('active');
    $element.addClass('active');

    this.setActiveClass($connectedElement);

    return this;
  }

  /**
   * Open Filters - Opens the filter submenu of the given element.
   *
   * @param $element
   * @returns {Filter}
   */
  openFilters($element) {
    let $parent = $element.closest('[data-filter]');
    let isOpen = $parent.hasClass('open');
    let taxonomy = $parent.attr('data-filter');
    let disconnectedFilter = false;

    if (this.masterParent) {
      disconnectedFilter = this.masterParent.querySelector('[data-filter-disconnected="' + taxonomy + '"]');
    }

    if (isOpen) {
      $parent.removeClass('open');

      if (disconnectedFilter) {
        disconnectedFilter.classList.remove('open');
      }
    } else {
      this.closeAllFilters();
      $parent.addClass('open');

      if (disconnectedFilter) {
        disconnectedFilter.classList.add('open');
      }
    }

    return this;
  }

  /**
   * Close All Filters - Closes all filters.
   *
   * @returns {Filter}
   */
  closeAllFilters() {
    this.$openButtons.parent().removeClass('open');

    if (this.masterParent) {
      let disconnectedFilters = this.masterParent.querySelectorAll('[data-filter-disconnected]');

      for (let i = 0; i < disconnectedFilters.length; i++) {
        let disconnectedFilter = disconnectedFilters[i];

        disconnectedFilter.classList.remove('open');
      }
    }

    return this;
  }

  /**
   * Checks whether sticky-nav-indicator is hidden with CSS `visibility` property
   *
   * @returns {boolean}
   */
  isStickyNavHidden() {
    const stickyNav = document.querySelector('.sticky-nav-indicator');
    if (stickyNav) {
      const stickyNavComputed = window.getComputedStyle(stickyNav);
      const isHidden = (stickyNavComputed.visibility === 'hidden') ? true : false;
      return isHidden;
    } else {
      return true;
    }
  }

  /**
   * Runs renderFilter() function when browser is crossing given breakpoint size
   *
   * @returns {boolean}
   */
  bindResizeEvent() {
    window.matchMedia('(min-width: 768px)').addListener(() => {
      this.renderFilter();
    });
  }

  /**
   * Render Filter - Renders the current filters.
   *
   * @returns {Filter}
   */
  renderFilter() {
    let filter = '[data-filter-element="unfiltered"], ';
    let found = false;

    $(this.jqueryFilterParent).html(this.elements);

    for (let key in this.filter) {
      let lowerKey = key.toLowerCase();

      if (this.filter.hasOwnProperty(key) && this.filter[key] !== 'all') {
        found = true;
        filter += '[data-filter-' + lowerKey + '*="|' + this.filter[key] + '|"]';
      }
    }

    if (found && this.isStickyNavHidden()) {
      let elements = $(this.jqueryFilterParent).find('[data-filter-element]');
      let detached = elements.not(filter);

      elements.filter(filter).css({visibility:'visible'}).show(300);
      detached.hide(300, function () {});
    } else {
      $(this.jqueryFilterParent).find('[data-filter-element]').css({visibility:'visible'}).show(300);
    }

    if (!this.allFiltersCleared) {
      $('[data-filter-indicators]').addClass('open');
    } else {
      $('[data-filter-indicators]').removeClass('open');
    }

    return this;
  }
}

export default Filter;
