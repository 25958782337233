'use strict';

import Swiper from 'swiper';
import $ from 'jquery';
import CustomEvent from './event.js';

function clearCurrentTab($tabs) {
  $tabs.removeClass('current');
}

function updateTabs(swiper, $tabs) {
  let realIndex = parseInt(swiper.realIndex) + 1;

  clearCurrentTab($tabs);
  $tabs.filter('[data-swiper-tab="' + realIndex + '"]').addClass('current');
}

function setCurrentTab($current) {
  $current.addClass('current');
}

function initSplitSectionSwiper() {
  let coverEvent = new CustomEvent('measureCover');

  new Swiper('[data-split-section-swiper]', {
    slidesPerView: 1,
    speed: 1000,
    autoplay: 4000,
    loop: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    onInit: function (swiper) {
      let $tabs = $(swiper.container).closest('section').find('[data-swiper-tab]');

      $tabs.on('click touchstart', function (e) {
        let $current,
          slideNumber;

        e.preventDefault();

        $current = $(e.currentTarget);
        slideNumber = $(this).attr('data-swiper-tab');

        clearCurrentTab($tabs);
        setCurrentTab($current);

        swiper.slideTo(slideNumber, 500);
      });

      swiper.on('onSlideChangeStart', function (swiper) {
        updateTabs(swiper, $tabs);
        coverEvent.trigger(swiper);
      });

      swiper.on('onTouchEnd', function (swiper) {
        updateTabs(swiper, $tabs);
      });

      window.addEventListener('startAutoplaySplitSlider', function() {
        swiper.startAutoplay();
      });

      window.addEventListener('stopAutoplaySplitSlider', function() {
        swiper.stopAutoplay();
      });
    }
  });
}

function initHeroSectionSwiper() {
  let coverEvent = new CustomEvent('measureCover');

  new Swiper('[data-hero-swiper]', {
    effect: 'fade',
    autoplay: 4000,
    speed: 2000,
    onInit: function (swiper) {
      swiper.on('onSlideChangeStart', function (swiper) {
        coverEvent.trigger(swiper);
        checkSlide();
      });

      swiper.on('onSlideChangeEnd', function (swiper) {
        let slide = $(swiper.slides[swiper.realIndex]);
        let video = slide.find('video');

        if (video.length === 0) {
          swiper.startAutoplay();
        }
        slide.prevAll().css('opacity', 0);
      });

      checkSlide();

      document.addEventListener('videoEnded', function() {
        swiper.slideNext();
      });

      function checkSlide() {
        let slide = $(swiper.slides[swiper.realIndex]);
        let video = slide.find('video');

        if (video.length > 0) {
          let playEvent = new CustomEvent('playEvent', video[0]);

          playEvent.trigger();
          swiper.stopAutoplay();
        }
      }
    }
  });
}

function initInstagramSwiper() {
  new Swiper('[data-instagram-swiper]', {
    slidesPerView: 4,
    loop: true,
    speed: 1000,
    autoplay: 4000,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    breakpoints: {
      1025: {
        slidesPerView: 3
      }
    }
  });
}

function initQuoteListSwiper() {
  new Swiper('[data-quote-list-swiper]', {
    slidesPerView: 3,
    loop: false,
    speed: 1000,
    autoplay: false,
    spaceBetween: 24,
    breakpoints: {
      1025: {
        slidesPerView: 1
      }
    }
  });
}

function initDesignTypeSwiper() {
  new Swiper('[data-design-type-swiper]', {
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 1000,
    loop: false,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    breakpoints: {
      768: {
        spaceBetween: 8
      }
    }
  });
}

function initSliderSwiper() {
  let customEvent = new CustomEvent('autoHeight');

  new Swiper('[data-slider-swiper]', {
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 1000,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    pagination: '.slider__indicators',
    paginationClickable: true,
    breakpoints: {
      768: {
        spaceBetween: 8,
        paginationClickable: true
      }
    },
    onInit: function (swiper) {
      let $swiper = $('[data-slider-swiper]');
      let $buttons = $swiper.find('.swiper-button-next, .swiper-button-prev');

      swiper.on('onTransitionStart', function () {
        $buttons.addClass('button-fade');
        setFirstSlide();
        customEvent.trigger();
      });

      swiper.on('onTransitionEnd', function () {
        $buttons.removeClass('button-fade');
      });

      setFirstSlide();

      function setFirstSlide() {
        if (swiper.activeIndex === 0) {
          $swiper.addClass('first-slide');
        } else {
          $swiper.removeClass('first-slide');
        }
      }
    }
  });
}

function initOneSlideSlider() {
  let $oneSlideSliders = $('[data-one-slide-slider]');

  $oneSlideSliders.each(function () {
    let thumbnailSlider;
    let $videos = $(this).find('video');

    let topSlider = new Swiper(this, {
      slidesPerView: 'auto',
      spaceBetween: 24,
      speed: 1000,
      loop: true,
      nextButton: '[data-one-slide-slider] .swiper-button-next',
      prevButton: '[data-one-slide-slider] .swiper-button-prev',
      breakpoints: {
        768: {
          spaceBetween: 8
        }
      }
    });

    let $parentSliderContainer = $(this).closest('[data-double-slider-container]');
    let $thumbnailSlider = $parentSliderContainer.find('[data-thumbnail-slider]');

    if ($thumbnailSlider.length > 0) {
      thumbnailSlider =  new Swiper($thumbnailSlider.get(0), {
        slidesPerView: 'auto',
        centeredSlides: false,
        touchRatio: 0.2,
        spaceBetween: 24,
        loop: true,
        slideToClickedSlide: true,
        nextButton: '.thumbnailSlider__next',
        prevButton: '.thumbnailSlider__prev',
        onSlideChangeStart: function() {
          stopVideos($videos);
        }
      });

      topSlider.params.control = thumbnailSlider;
      thumbnailSlider.params.control = topSlider;
    }

    // thumbnailSlider.nextButton[0].addEventListener('click' ,function () {
    //   topSlider.slideNext();
    // });
    //
    // thumbnailSlider.prevButton[0].addEventListener('click' ,function () {
    //   topSlider.slidePrev();
    // });
    //
    // topSlider.nextButton[0].addEventListener('click', function () {
    //   thumbnailSlider.slideNext();
    // });
    //
    // topSlider.prevButton[0].addEventListener('click', function () {
    //   thumbnailSlider.slidePrev();
    // });
  });
}

function stopVideos($videos) {
  $videos.each(function () {
    if (typeof this.pause !== 'undefined') {
      let $button = $(this).parent().find('a');

      if ($button.hasClass('button--playing')) {
        $button.click();
      }
    } else {
      this.src = '';
      this.src = this.getAttribute('data-src');
    }
  })
}

export default {
  initSplitSectionSwiper: initSplitSectionSwiper,
  initInstagramSwiper: initInstagramSwiper,
  initDesignTypeSwiper: initDesignTypeSwiper,
  initQuoteListSwiper: initQuoteListSwiper,
  initSliderSwiper: initSliderSwiper,
  initOneSlideSlider : initOneSlideSlider,
  initHeroSectionSwiper : initHeroSectionSwiper,
};
