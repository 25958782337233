/**
 *  Auto Height Element
 *
 *  @author Konstantinos Kataras
 */

import globals from '../settings/globals';
import throttle from 'throttle-debounce/throttle';
import $ from 'jquery';

function AutoHeightElement ($parent) {
  this.$parent = $parent;
  this.$children = this.$parent.find('[data-auto-align]');
  this.maxHeight = null;
  this.inRange = false;
  this.breakpoints = $parent.attr('data-auto-align-parent');
  this.hasBreakpoints = (this.breakpoints !== "");
  this.childrenCategories = [];

  const throttledAutoHeight = throttle(100, () => {
    this.autoHeight();
  });

  $(document).on('autoHeight', throttledAutoHeight);

  if (this.hasBreakpoints) {
    this.range = this.breakpoints.split(" ");

    $(document).on('BreakpointChange', function() {
      this.setInRange();
      this.autoHeight();
    }.bind(this));

  } else {
    this.inRange = true;

    $(window).resize(function () {
      this.autoHeight();
    }.bind(this));
  }

  $(window).on('load', function () {
    this.autoHeight();
  }.bind(this));

  this.$children.each(function (index) {
    var found = false,
      $child = $(this.$children.get(index)),
      attr = $child.attr('data-auto-align'),
      height = parseInt($child.innerHeight()),
      category;

    for (var i = 0; i < this.childrenCategories.length; i+=1) {
      if (attr === this.childrenCategories[i].attr) {
        this.childrenCategories[i].elements.push($child);
        this.childrenCategories[i].maxHeight = height > this.childrenCategories[i].maxHeight ? height : this.childrenCategories[i].maxHeight;
        found = true;
        break;
      }
    }

    if (!found) {
      category = {
        attr: attr,
        elements: [$child],
        maxHeight: 0
      };

      this.childrenCategories.push(category);
    }

  }.bind(this));

  this.setInRange = function () {
    var found = false;

    for (var i = 0; i < this.range.length; i++) {
      if (globals.activeBreakpointRange === this.range[i]) {
        found = true;
      }
    }

    this.inRange = found;
  };

  this.getMaxHeight = function () {
    var height;

    for (var i = 0; i < this.childrenCategories.length; i+=1) {
      this.childrenCategories[i].maxHeight = 0;

      for (var k = 0; k < this.childrenCategories[i].elements.length; k+=1) {
        this.releaseHeight(this.childrenCategories[i].elements[k]);
        height = parseInt(this.childrenCategories[i].elements[k].innerHeight());
        this.childrenCategories[i].maxHeight = (height > this.childrenCategories[i].maxHeight) ? height : this.childrenCategories[i].maxHeight;
        this.restoreHeight(this.childrenCategories[i].elements[k]);
      }
    }

    this.afterMeasurementCallback();
  };

  this.releaseHeight = function (element) {
    element.css({
      visibility: "hidden",
      position: "static",
      display: "block",
      height: ""
    });
  };

  this.restoreHeight = function (element) {
    element.css({
      visibility: "",
      position: "",
      display: ""
    });
  };

  this.setHeight = function (height) {
    this.$children.each(function () {
      $(this).css('height', height);
    });
  };

  this.setMaxHeight = function () {
    for (var i = 0; i < this.childrenCategories.length; i+=1) {
      for (var k = 0; k < this.childrenCategories[i].elements.length; k+=1) {
        this.childrenCategories[i].elements[k].css('height', this.childrenCategories[i].maxHeight + 'px');
      }
    }
  };

  this.autoHeight = function () {
    if (this.inRange) {
      this.getMaxHeight();
      this.setMaxHeight();

    } else {
      this.setHeight('auto');

    }
  };

  if (this.hasBreakpoints) {
    this.setInRange();
  }
}

AutoHeightElement.prototype.afterMeasurementCallback = function () {};

export default AutoHeightElement;
