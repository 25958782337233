/**
 * Scroll Animation - Scrolls to a specific point (Jquery plugin).
 *
 */

import $ from 'jquery';

var $html = $('html, body');

function scrollAnimation(position, callback, object, speed, easing) {
  if (typeof speed === 'undefined') {
    speed = 'slow';
  }

  if (typeof easing === 'undefined') {
    easing = 'swing';
  }

  $html.animate({scrollTop: position}, speed, easing, function () {
    if (typeof callback !== 'undefined') {
      callback.apply(object);
    }
  });
}

export default scrollAnimation;
