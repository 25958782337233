/**
 * Scroll to - Scrolls to an anchor tab with an animation.
 */

import utilities from '../utilities/utilities.js';

const moduleAttr = 'data-scroll-to';

let links = document.querySelectorAll('['+ moduleAttr +']');

for(let i = 0; i < links.length; i++) {
  let link = links[i];

  link.addEventListener('click', function (e) {
    e.preventDefault();
    let scrollToElement = document.getElementById(link.getAttribute(moduleAttr));
    let offset = (link.hasAttribute('data-scroll-to-offset')) ? parseInt(link.getAttribute('data-scroll-to-offset')) : 0;
    let headerHeight = document.querySelector('header').offsetHeight;

    utilities.scrollAnimation(utilities.getCoordinates(scrollToElement).top + offset - headerHeight);
  });
}
