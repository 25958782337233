let freezeVp = function(e) {
  e.preventDefault();
};

function BodyScrolling() {
  this.enableScrolling = function () {
    document.body.removeEventListener('touchmove', freezeVp, false);
  };

  this.disableScrolling = function () {
    document.body.addEventListener('touchmove', freezeVp, false);
  };
}

let bodyScrolling = new BodyScrolling();

export default bodyScrolling;
