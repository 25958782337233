/**
 *  Image Modal
 *
 */

import $ from 'jquery';
import Modal from './modal';
import BreakpointRange from './breakpoint-range';

const IMAGE_MODAL_ACTION = '[data-image-modal-action]';
const IMAGE_MODAL_IMAGE = '[data-image-modal="image"]';
const IMAGE_MODAL_ELEMENT = '[data-image-modal="modal"]';

let $imageLinks= $(IMAGE_MODAL_ACTION);

function loadImageModal () {
  $imageLinks.each(function () {
    let $element = $(this);
    let $modal = $('body').find(IMAGE_MODAL_ELEMENT);
    let modal = new Modal($modal);

    $element.on('click', function (e) {
      e.preventDefault();
      console.log(1);

      $modal.find(IMAGE_MODAL_IMAGE).css('background-image', 'url("' +  + '")')
      modal.open();
    });
  });
}

export default loadImageModal;
