/**
 *  Menu
 */

import globals from '../settings/globals.js';

class Menu {
  constructor() {
    this.action = document.querySelector('[data-menu-open]');
    this.navigation = document.querySelector('.navigation--main');
    this.lines = this.action.querySelectorAll('.menuButt__line');
    this.parentButtons = document.querySelectorAll('[data-menu-parent]');
    this.enableClickEvent();
    this.enableParentButtonsClickEvent();
    this.resetAnimation();
    document.addEventListener('closeMainMenu', function () {
      this.closeMenu();
    }.bind(this));
  }

  enableClickEvent() {
    this.action.addEventListener('click', function (e) {
      e.preventDefault();

      this.navigation.classList.toggle('open');

      for (let i = 0; i < this.lines.length; i++) {
        this.lines[i].classList.add('animate');
      }

      this.action.classList.toggle('menuButt--close');
    }.bind(this));
  }

  closeMenu() {
    this.navigation.classList.remove('open');
    this.action.classList.remove('menuButt--close');
  }

  enableParentButtonsClickEvent() {
    for (let i = 0; i < this.parentButtons.length; i++) {
      let button = this.parentButtons[i];

      button.addEventListener('click', function (e) {
        e.preventDefault();
        let parent = button.parentNode;

        button.blur();
        parent.blur();
        parent.classList.toggle('open');
      });
    }
  }

  resetAnimation() {
    document.addEventListener('BreakpointChange', function() {
      if (globals.activeBreakpointRange !== 'xs' && globals.activeBreakpointRange !== 'sm') {
        this.navigation.style.transition = "opacity 0.3s ease-in-out";
      } else {
        this.navigation.style.transition = "opacity 0.3s ease-in-out, transform 0.3s ease-in-out";
      }
    }.bind(this));
  }
}

export default Menu;
