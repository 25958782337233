/**
 * Breakpoint Range
 *
 */

import globals from '../settings/globals.js';

class BreakpointRange {
  constructor(range = []) {
    this.range = range;
    this.rangeLength = range.length;
  }

  isOnRange() {
    let onRange = false;

    for (let i = 0; i < this.rangeLength; i++) {
      let found = this.isBreakpointOnRange(this.range[i]);

      if (found) {
        onRange = found;
        break;
      }
    }

    return onRange;
  }

  isBreakpointOnRange(breakpoint) {
    return (breakpoint === globals.activeBreakpointRange);
  }
}

export default BreakpointRange;
