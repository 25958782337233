'use strict';

const HALF_CIRCLE = 180;
const TO_HALF = 2;
const ROTATE_IMAGE_ATTRIBUTE = 'data-rotate-image';

class RotateImage {
  constructor() {
    this.elements = document.querySelectorAll('[' + ROTATE_IMAGE_ATTRIBUTE + ']');

    if (this.elements.length > 0) {
      for (let i = 0; i < this.elements.length; i += 1) {
        this.rotateImage(i);
      }
    }
  }

  rotateImage(i) {
    // Based on http://stackoverflow.com/a/3793474
    const image = new Image();

    let element = this.elements[i];
    let angle = parseInt(element.getAttribute(ROTATE_IMAGE_ATTRIBUTE));

    image.onload = () => {


      if (angle === 90) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = image.naturalHeight;
        canvas.height = image.naturalWidth;

        let angleInRadians = angle * ( Math.PI / HALF_CIRCLE );
        let x = canvas.width / TO_HALF;
        let y = canvas.height / TO_HALF;
        let width = image.width;
        let height = image.height;

        context.translate(x, y);
        context.rotate(angleInRadians);
        context.drawImage(image, -width / TO_HALF, -height / TO_HALF, width, height);
        context.rotate(-angleInRadians);
        context.translate(-x, -y);

        element.src = canvas.toDataURL('image/png');

      } else {
        element.src = element.dataset.image;
      }

    };

    image.src = element.dataset.image;
  }
}

new RotateImage();
