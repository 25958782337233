/**
 *  Breakpoint change event launcher
 */

import globals from '../settings/globals';
import CustomEvent from './event.js';

class BreakpointTracker {

  constructor() {
    globals.activeBreakpointRange = this.getBreakpoint();

    this.event = new CustomEvent('BreakpointChange');
    this.event.trigger();

    if (document.addEventListener) {
      window.addEventListener('resize', function () {
        this.onResize();
      }.bind(this));
    } else {
      throw 'NO ADD EVENT LISTENER EVENT PLEASE UPGRADE YOUR BROWSER';
    }
  }

  onResize() {
    let activeBreakpointRange = this.getBreakpoint();

    if (activeBreakpointRange !== globals.activeBreakpointRange) {
      globals.activeBreakpointRange = activeBreakpointRange;
      this.event.trigger();
    }
  }

  getBreakpoint() {
    let width = window.innerWidth;

    if (width < globals.breakpoints.xs) {
      return 'xs';
    } else if (width < globals.breakpoints.sm) {
      return 'sm';
    } else if (width < globals.breakpoints.md) {
      return 'md';
    } else if (width < globals.breakpoints.lg) {
      return 'lg';
    } else {
      return 'xl';
    }
  }
}

export default BreakpointTracker;
