/**
 *  Get Transition Event.
 *
 * @returns {*}
 */

function getTransitionEvent(element) {
  let transition,
      transitions = {
        'transition':'transitionend',
        'OTransition':'oTransitionEnd',
        'MozTransition':'transitionend',
        'WebkitTransition':'webkitTransitionEnd'
  };

  for(transition in transitions){
    if(element.style[transition] !== undefined){
      return transitions[transition];
    }
  }
}

export default getTransitionEvent;
