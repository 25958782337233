/**
 *  Auto height
 *
 *  @author Konstantinos Kataras
 */

import AutoHeightElement from './auto-height-element';
import $ from 'jquery';

var AutoHeight = {
  AutoHeightElements: [],

  init: function () {
    var that = this;

    $('[data-auto-align-parent]').each(function () {
      that.AutoHeightElements.push(new AutoHeightElement($(this)));
    });
  },

  applyAutoHeight: function () {
    for (var i = 0; i < this.AutoHeightElements.length; i++) {
      this.AutoHeightElements[i].autoHeight();
    }
  }
};

export default AutoHeight;
