/**
 * Scroll to on load
 * @author Konstantinos Kataras
 */

import utilities from '../utilities/utilities.js';
import globals from '../settings/globals.js';

const SCROLL_TO_ON_LOAD_ATTRIBUTE = 'data-scroll-to-on-load';
const SCROLL_TO_ON_LOAD_OFFSET_ATTRIBUTE = 'data-scroll-to-on-load-offset';
const SCROLL_TO_ON_LOAD_LINK = 'data-scroll-to-on-load-link';

let scrollToOnLoadElements = document.querySelectorAll('['+ SCROLL_TO_ON_LOAD_ATTRIBUTE + ']');
let hash = location.hash;

if (hash !== '' && scrollToOnLoadElements !== null) {
  for (let i = 0; i < scrollToOnLoadElements.length; i += 1) {
    let scrollToOnLoadElement = scrollToOnLoadElements[i];
    let attribute = scrollToOnLoadElement.getAttribute(SCROLL_TO_ON_LOAD_ATTRIBUTE);
    let offset = parseInt(scrollToOnLoadElement.getAttribute(SCROLL_TO_ON_LOAD_OFFSET_ATTRIBUTE)) || 0;

    if (hash === attribute) {
      utilities.scrollAnimation(utilities.getCoordinates(scrollToOnLoadElement).top - globals.headerHeight - globals.wpAdminHeight + offset);
    }
  }
}

let scrollToOnLoadLinks = document.querySelectorAll('['+ SCROLL_TO_ON_LOAD_LINK + ']');

for (let i = 0; i < scrollToOnLoadLinks.length; i += 1) {
  let scrollToOnLoadLink = scrollToOnLoadLinks[i];
  let scrollToOnLoadLinkAttr = scrollToOnLoadLink.getAttribute(SCROLL_TO_ON_LOAD_LINK);
  let scrollToOnLoadElement = document.querySelector('['+ SCROLL_TO_ON_LOAD_ATTRIBUTE + '="' + scrollToOnLoadLinkAttr + '"' + ']');

  if (scrollToOnLoadElement) {
    let offset = parseInt(scrollToOnLoadElement.getAttribute(SCROLL_TO_ON_LOAD_OFFSET_ATTRIBUTE)) || 0;

    scrollToOnLoadLink.addEventListener('click', function () {
      utilities.scrollAnimation(utilities.getCoordinates(scrollToOnLoadElement).top - globals.headerHeight - globals.wpAdminHeight + (offset / 2));
    });
  }
}
