/**
 * Scroll after transition - Scrolls to after transition ends.
 */

import utilities from '../utilities/utilities.js';

const moduleAttr = 'data-scroll-after-transition';

let elements = document.querySelectorAll('['+ moduleAttr +']');

for(let i = 0; i < elements.length; i++) {
  let element = elements[i];

  element.addEventListener(utilities.getTransitionEvent(element), function (e) {
    e.preventDefault();
    let scrollToElement = document.getElementById(element.getAttribute(moduleAttr));

    if ((scrollToElement.classList.contains('js-switch-on') && e.propertyName === 'max-height')) {
      utilities.scrollAnimation(utilities.getCoordinates(scrollToElement).top - 100);
    }
  });
}
