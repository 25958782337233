/**
 * Click out.
 *
 * @Author Konstantinos Kataras
 *
 */

import utilities from './../utilities/utilities.js';
import CustomEvent from './event.js';

class ClickOut {
  constructor(options) {
    this.enabled = true;

    if (typeof options !== 'undefined') {
      this.options = options;
      this.setupObject();
    }

    if ((this.callback || this.event) && this.element) {
      this.registerClickOut();
    } else {
      console.error('Click out module not initialised properly');
    }
  }

  registerClickOut() {
    document.addEventListener('click', this.clickOutAction.bind(this));

    return this;
  }

  clickOutAction(event) {
    let clickedElement = event.target;

    if (this.isClickOutEvent(clickedElement) && this.enabled) {
      if (this.event) {
        this.event.trigger();
      }

      if (this.callback) {
        this.callback();
      }
    }
  }

  enableClickOut() {
    this.enabled = true;

    return this;
  }

  disableClickOut() {
    this.enabled = false;

    return this;
  }

  isExceptionElement(element) {
    let found = false;

    for (let i = 0; i < this.exceptionElements.length; i += 1) {
      let exceptionElement = this.exceptionElements[i];

      if (element.isSameNode(exceptionElement)) {
        found = true;
        break;
      }

      if (this.exceptionSelector && (utilities.getClosest(element, this.exceptionSelector) !== null)) {
        found = true;
        break;
      }
    }

    return found;
  }

  isClickOutEvent(clickedElement) {
    let hasParentElementTheSelector = (utilities.getClosest(clickedElement, this.selector) !== null);
    let isTheSameElement = clickedElement.hasAttribute(this.selector);
    let isExceptionElement = this.isExceptionElement(clickedElement);

    return ((!hasParentElementTheSelector && !isTheSameElement) && !isExceptionElement);
  }

  setupObject() {
    this.selector = this.options.selector || false;
    this.element = this.options.element || false;
    this.callback = this.options.callback || false;
    this.eventName = this.options.eventName || false;
    this.exceptionElements = this.options.exceptionElements || false;
    this.exceptionSelector = this.options.exceptionSelector || false;
    this.event = false;

    if (this.eventName) {
      this.event = new CustomEvent(this.eventName);
    }

    if (!this.element) {
      let elementSelectorQueryResult = document.querySelector(this.selector);

      if (elementSelectorQueryResult !== null) {
        this.element = elementSelectorQueryResult;
      }
    }
  }
}

export default ClickOut;
