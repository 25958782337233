/**
 * Animations
 *
 */

import ScrollMagic from 'scrollmagic';
import 'gsap';
import globals from '../settings/globals.js'
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js'
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js'
import $ from 'jquery';

if ($('[data-animate-parallax="parent"]').length > 0) {
  let heroScrollController = new ScrollMagic.Controller({
    globalSceneOptions: {
      duration: '100%',
      offset: -62,
      triggerHook: 0
    }
  });

  let heroParallaxAnimation = new ScrollMagic.Scene({triggerElement: '[data-animate-parallax="parent"]'})
    .setTween('[data-animate-parallax="element"]', {y: 200, ease: Linear.easeNone})
    .addTo(heroScrollController);

  if (globals.debug) {
    heroParallaxAnimation.addIndicators();
  }
}

let $animateTriggers = $('[data-animate-trigger]');

if ($animateTriggers.length > 0) {
  let triggerController = new ScrollMagic.Controller({
    globalSceneOptions: {
      duration: 0,
      offset: 0,
      triggerHook: 0.9
    }
  });

  $animateTriggers.each(function () {
    let triggerScene = new ScrollMagic.Scene({triggerElement: this})
      .setClassToggle(this, 'start-animate')
      .addTo(triggerController);

    triggerScene.on('enter', function () {
      triggerScene.destroy();
    });

    if (globals.debug) {
      triggerScene.addIndicators();
    }
  });
}

let $scrollTriggers = $('[data-scroll-trigger]');

if ($scrollTriggers.length > 0) {
  let triggerController = new ScrollMagic.Controller({
    globalSceneOptions: {
      offset: 0,
      triggerHook: 0.8
    }
  });

  $scrollTriggers.each(function () {
    let affectedElement = document.querySelector('#' + this.getAttribute('data-scroll-trigger'));
    let triggerScene = new ScrollMagic.Scene({triggerElement: this})
    .setClassToggle(affectedElement, 'scroll-trigger')
    .duration(this.offsetHeight)
      .addTo(triggerController);

    if (globals.debug) {
      triggerScene.addIndicators();
    }
  });
}

const ADD_CLASS_ATTR = 'data-add-class';
const ADD_CLASS_SELECTOR = '[' + ADD_CLASS_ATTR + ']';
Array.prototype.slice.call(document.querySelectorAll(ADD_CLASS_SELECTOR)).forEach(node => {
  const classname = node.getAttribute(ADD_CLASS_ATTR);
  node.classList.add(classname);
});
