/**
 * Autoplay
 *
 */

import CustomEvent from './event.js';
import $ from 'jquery';

class Autoplay {
  constructor() {
    this.$video = $('[data-autoplay]');
    this.$playVideo = $('[data-play-video]');

    if (this.$video.length > 0) {
      this.autoplay();
    }

    if (this.$playVideo.length > 0) {
      this.playVideo();
    }

    this.startAutoplayEvent = new CustomEvent('startAutoplaySplitSlider');
    this.stopAutoplayEvent = new CustomEvent('stopAutoplaySplitSlider');
    this.videoEndedEvent = new CustomEvent('videoEnded');
  }

  playVideo() {
    this.$playVideo.each(function (index) {
      let $currentPlayVideo = $(this.$playVideo.get(index));
      let $video = $currentPlayVideo.parent().find('video');

      $video.on('ended', function () {
        $currentPlayVideo.removeClass('button--playing');
        this.startAutoplayEvent.trigger();
        this.videoEndedEvent.trigger();
      }.bind(this));

      $currentPlayVideo.on('click', function (e) {
        e.preventDefault();

        if ($currentPlayVideo.hasClass('button--playing')) {
          $currentPlayVideo.removeClass('button--playing');
          $video.get(0).pause();
        } else {
          $currentPlayVideo.addClass('button--playing');
          $video.get(0).play();
        }

        this.stopAutoplayEvent.trigger();
      }.bind(this));

    }.bind(this));
  }

  autoplay() {
    this.$video.each(function (index) {
      let video = this.$video.get(index);
      let playPromise;
      let $video = $(video);
      let $button = $video.closest('[data-autoplay-parent]').find('[data-autoplay-button]');

      let coverEvent = new CustomEvent('applyCoverToElement');

      if (video.getAttribute('data-autoplay') !== 'slider') {
        playPromise = video.play();
      } else {
        video.addEventListener('playEvent', function() {
          $button.hide();
          playPromise = video.play();
        });
      }

      $button.hide();

      $button.on('click', function (e) {
        e.preventDefault();
        let element = $video.closest('[data-cover="container"]')[0];
        $video.removeClass('cover--measured');
        $video.show();
        coverEvent.trigger(element);
        video.play();
        $button.hide();
      }.bind(this));

      $video.on('ended', function () {
        $button.show();
        this.videoEndedEvent.trigger();
      }.bind(this));

      if (typeof playPromise !== 'undefined') {
        playPromise.then(function () {
          $video.show();
        }.bind(this)).catch(function () {
          $video.hide();
          $button.show();
        }.bind(this));
      }
    }.bind(this));
  }
}

export default Autoplay;
